.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#desktopBanner{
	font-size: 10vh;
  color:#CC1F1F;
  font-style: italic;
}

@media screen and (max-width: 500px) {
  #desktopBanner, #theWildLogo {
		display: none;
	}

}

@media screen and (min-width: 500px) {
  .StoryPane{
		display: none;
	}
	
	#theWildLogo{
		height: 50vh;
		margin-top: 15vh;
	}
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
