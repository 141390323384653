.Title {
	padding-top: 5vh;
	
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-style: italic;
	font-size: 6vw;
	
	color: white;

	text-shadow: -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red, 1px 1px 0 red;
}