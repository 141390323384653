.Card {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 10% 15% 15% 15%;
	margin-left: 12vw;
	margin-right: 13vw;
	margin-top: 5vh;
	margin-bottom: 5vh;
	height: 30vh;
	border-radius: 10px;
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.4);
}

.button {
	margin-top: 15%;
	background-color: red;
	outline: none;
	color: white;
	border-radius: 30px;
	height: 5vh;
	border: 0px;
	padding-top: 5%;	
	text-decoration: none;
}